import type { AlertVariant } from '@docue/docue-ui-v2/ui-components/DTAlert/types'
import type { Component, ComputedRef, InjectionKey, Raw } from 'vue'

export interface Notification {
  status: 'success' | 'warning'
  message: string
  ms?: number
  uid: number
}

interface IListItemBoundary {
  startLoading: () => void
  stopLoading: () => void
  setError: (message: string) => void
  showSuccess: (cb?: () => void) => void
}

export const ListItemBoundary = Symbol('ListItemBoundary') as InjectionKey<IListItemBoundary>

export interface TimerController {
  start: () => void
  stop: () => void
  pause: () => void
  resume: () => void
  remaining: ComputedRef<number>
}

export type ToastVariant = keyof typeof AlertVariant

export interface Toast {
  id: string
  content: string
  variant?: ToastVariant
  icon?: Raw<Component>
  timeout?: number
}

export interface IDocumentsSidebarProvider {
  openSigningTab: () => void
}
export const DocumentsSidebarProvider = Symbol('DocumentsSidebarProvider') as InjectionKey<IDocumentsSidebarProvider>
